import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  setColor,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  print,
  plotFunction,
  setWidth
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-8,4,-6,6);
makeResponsive(graph);
graph.options.layer['image']=26;
graph.options.layer['text']=20;
graph.options.layer['html']=2;
graph.options.layer['line']=2;
graph.options.layer['point']=10;
graph.options.layer['circle']=1;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const PtmList = [1/4, 1/2, 3/4, 6/5, 8/5];
const ZList=[1, 0, 1, -1, 1];
const XList=[-2, 1, 1, 4, -2];
const YList=[0, 1, 0, -2, -2];
const UList=[0, 0, -1, 0, 1];
const Func =['x^2 - 2x', 'x + 1', '-x^3 + x^2 + x', '-x^2 +4x -2', 'x^3 + x^2 - 2x -2' ]
const Fx = [-1, -2, -1, 0, -2];
const Fy = [2, 2, 2, 3, 1];
var Ptm = drawPoint(graph, PtmList[0], 8);
Ptm.setAttribute({visible:false});
const answers = [['0', '1', '2', 'Infinite'],['0', '1', '2', 'Infinite'], ['0', '1', '2', 'Infinite'], ['0', '1', '2', 'Infinite'], ['0', '1', '2', 'Infinite']];
const index_right_answer = [1,3,2,1,2];
/**/
/********************************************************************************************/
var quad =plotFunction(graph, function(x){return UList[i]*x*x*x+ZList[i]*x*x+XList[i]*x+YList[i]}, ()=>Fx[i], ()=>Fy[i]);
setWidth(graph, quad, 4);
var PtA = drawPoint(graph, ()=>Fx[i], ()=>UList[i]*Fx[i]*Fx[i]*Fx[i]+ZList[i]*Fx[i]*Fx[i]+XList[i]*Fx[i]+YList[i]);
labelIt(graph, PtA, 'a');
setColor(graph, PtA, 'blue');

var PtB = drawPoint(graph, ()=>Fy[i], ()=>UList[i]*Fy[i]*Fy[i]*Fy[i]+ZList[i]*Fy[i]*Fy[i]+XList[i]*Fy[i]+YList[i]);
labelIt(graph, PtB, 'b');
setColor(graph, PtB, 'blue');
//PtA.label.setAttribute({offset:[0, 10]});
//quad.setAttribute({strokeWidth:, highlight:true, strokeColor:'red'});

/***********************************************************************************/
/*************************************PRINT ANSWER ****************************************/
//print(graph, ()=> (0.5*(180-2*alpha.Value()*180/Math.PI)).toFixed(1))
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, function(){return 'How many points satisfy MVT for function f(x) on interval [a, b]?'});
var comment = placeComment(graph, function(){return 'Function f(x) = '+ Func[i]});
var note = writeHTMLText(graph, positionX, positionY+height/2., 'You did not attempt!');
note.setAttribute({visible:false});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placeRedo(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
var i=0; var k=0; var vis=false;
///////////////////////////////////////////////////////////////
var ansList = ['0', '1', '2', 'Infinite'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li> Locate the coordinates of point B and point C.</li><br><li> Use angle bisector theorem to find the ratio of BM to CM. </li> <br><li> Use division formula to find coordinates of point M. </li></ol>');
hint.setAttribute({visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=PtmList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<PtmList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<PtmList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<PtmList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==PtmList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  comment.setAttribute({visible:false});
  //alpha.setAttribute({visible:false});
  //beta.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  //Ptm.moveTo([PtmList[i], 8], 200);
  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    //Ptm.moveTo([PtmList[0], 8], 200);
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:k});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
  comment.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
  ////////////////////////Code specific changes go here/////////////////
	//alpha.setAttribute({visible:!vis});
  //beta.setAttribute({visible:!vis});
  //////////////////////////////////////////////////////////////////////
});
/******************END OF FILE ***************************/
}
}
export default Boxes;
